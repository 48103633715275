import { axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { MarshallingTypeExclusionRepository } from "./MarshallingTypeExclusionRepo";
import { MarshallingTypeExclusionEntity } from "domain/entity/MarshallingTypeExclusion/MarshallingTypeExclusionEntity";

export const MarshallingTypeExclusionRepoImpl = (): MarshallingTypeExclusionRepository => {

    const url = '/v1/marshallingTypeExclusionSearch';
    const updateUrl = '/v1/marshallingTypeExclusion'

    const getMarshallingTypeExclusion = async(): Promise<MarshallingTypeExclusionEntity[]> => {
        return axiosPostData(referenceAxiosInstance, `${url}`, []).then(res => {
            //return res.data;
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    const createNewCriteria = async(newData: MarshallingTypeExclusionEntity) : Promise<ResponseEntity> => {
        return await axiosPostData(referenceAxiosInstance, `${updateUrl}`, newData)
    }

    const updateCriteria = async(updatedData: MarshallingTypeExclusionEntity): Promise<ResponseEntity> => {
        return await axiosPutData(referenceAxiosInstance, `${updateUrl}`, updatedData)
    }

    return {
        getMarshallingTypeExclusion: getMarshallingTypeExclusion,
        createNewCriteria: createNewCriteria,
        updateCriteria: updateCriteria,
    }
}