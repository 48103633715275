export const MarshallingTypeExclusionConstant = {
    Charge: {
        CHARGE: "Charge",
    },
    Title: {
        MARL_TYPE_EXCL_MAIN: "Marshalling Exclusion Criteria",
    },

    Table: {
        LABEL_NAME: "Marshalling Exclusion Criteria List",
        CHARGE_ON_COMPANY: "Charge On Company",
        BILL_TO_COMPANY: "Bill To Company",
        MARSHALLING_TYPE: "Marshalling Type",
        ACTIVE_IND: "Active Ind",
    },
}