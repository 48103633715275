import { useMemo } from "react";
import { OpsLineRepoImpl } from "domain/repository/OpsLine/OpsLineRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { TerminalRepoImpl } from "domain/repository/Terminal/TerminalRepoImpl";
import { MarshallingTypeExclusionVM } from "presentation/viewModel/MarshallingTypeExclusion/MarshallingTypeExclusionVM";
import { MarshallingTypeExclusionRepoImpl } from "domain/repository/MarshallingTypeExclusion/MarshallingTypeExclusionRepoImpl";
import { useMarshallingTypeExclusionTracked } from "presentation/store/MarshallingTypeExclusion/MarshallingTypeExclusionProvider";

export const useMarshallingTypeExclusionVM = () => {
    const [, setCriteriaState] = useMarshallingTypeExclusionTracked();
    const marshallingTypeExclVM = useMemo(() =>
        MarshallingTypeExclusionVM({
            dispatch: [setCriteriaState],
            marshallingTypeExclRepo: MarshallingTypeExclusionRepoImpl(),
            opsLineRepo: OpsLineRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            terminalRepo: TerminalRepoImpl(),
        }), [setCriteriaState])

    return marshallingTypeExclVM
}

