import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { MarshallingTypeExclusionConstant } from "./MarshallingTypeExclusionConstant";

const MARL_TYPE_EXCL_MAIN_CONSTANT = MarshallingTypeExclusionConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_MARL_TYPE_EXCL_COMP_COL_DEF: any[] = [
    {
        headerName: MARL_TYPE_EXCL_MAIN_CONSTANT.CHARGE_ON_COMPANY,
        field: "chargeOnCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
        // checkboxSelection: true,
        // headerCheckboxSelection: true,
    },
    {
        headerName: MARL_TYPE_EXCL_MAIN_CONSTANT.BILL_TO_COMPANY,
        field: "billToCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: MARL_TYPE_EXCL_MAIN_CONSTANT.MARSHALLING_TYPE,
        field: "marshallingType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: MARL_TYPE_EXCL_MAIN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },



].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
