import * as yup from 'yup';

export const commonMarshallingTypeExclusionValidation: { [x: string]: any; } = {
  chargeOnCompany: yup.string().required("Missing"),
  billToCompany: yup.string().required("Missing"),
  marshallingType: yup.string().required("Missing"),
  activeInd: yup.string().required("Missing"),
};

export const marshallingTypeExclusionValidation: { [x: string]: any; } = {
  ...commonMarshallingTypeExclusionValidation,
};

export const marshallingTypeExclusionValidationSchema = yup.object().shape(marshallingTypeExclusionValidation);
