import { EMPTY_MARL_TYPE_EXCL_MODEL } from "presentation/model/MarshallingTypeExclusion/MarshallingTypeExclusionModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: MarshallingTypeExclusionProvider,
    useTracked: useMarshallingTypeExclusionTracked
} = createContainer(() => useState(EMPTY_MARL_TYPE_EXCL_MODEL), {concurrentMode: true});
export { MarshallingTypeExclusionProvider, useMarshallingTypeExclusionTracked };

