import { MarshallingTypeExclusionConstant } from "presentation/constant/MarshallingTypeExclusion/MarshallingTypeExclusionConstant";
import { useMarshallingTypeExclusionVM } from "presentation/hook/MarshallingTypeExclusion/useMarshallingTypeExclusionVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useMarshallingTypeExclusionTracked } from "presentation/store/MarshallingTypeExclusion/MarshallingTypeExclusionProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useMemo, useState } from "react"
import { FieldType, HPHButton, IFieldValue, Loader, HPHCheckbox, IconButton } from "veronica-ui-component/dist/component/core"
import { SidebarActionCross, SidebarCaption, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled"


export const MarshallingTypeExclusionPanel = () => {
    const [criteriaState] = useMarshallingTypeExclusionTracked();
    const criteriaVM = useMarshallingTypeExclusionVM();
    const CRITERIA_CONSTANT = MarshallingTypeExclusionConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const {currentSelectedRow} = criteriaState;
    const { isAdd, isEditable, isRead, isSaveClicked, editingCriteriaEntity, allFormState} = criteriaState.masterState;
    const currentEntity = isRead? currentSelectedRow : editingCriteriaEntity;
    const messageBarVM = useMessageBarVM();  

    const handleCancelClick = () => {
        criteriaVM.onCancel();
    }

    const handleSaveClick = useCallback(async ()  => {
        let isSave = true;
        if(isAdd && criteriaState.criteriaEntityList.some(item => (item.chargeOnCompany === currentEntity.chargeOnCompany 
            && item.billToCompany === currentEntity.billToCompany && item.marshallingType === currentEntity.marshallingType))){
                isSave = false;
        }
        if(isEditable){
            if(criteriaState.criteriaEntityList.filter(item => item.id !== currentEntity.id).some(item => (item.chargeOnCompany === currentEntity.chargeOnCompany 
                && item.billToCompany === currentEntity.billToCompany && item.marshallingType === currentEntity.marshallingType))){
                isSave = false;
            }
        }
        if(isSave){
            setIsLoading(true);
            criteriaVM.onComponentSaveClicked();
            criteriaVM.onSave(currentEntity, isAdd).then((res) => {   
                if(!res){
                    setIsLoading(false);
                    criteriaVM.searchEmptyPoolExclusion().then(()=>{
                        setIsLoading(false);
                    }).catch((error) => {                    
                        setIsLoading(false);
                    });
                    messageBarVM.showSuccess('Save Successfully!');
                }else{
                    setIsLoading(false);              
                }
            });
        }else{
            messageBarVM.showError('Duplicate record found, please check the key: Charge on Company, Bill To Company, Marshalling Type.');
        }
    },[criteriaVM, currentEntity, isAdd, messageBarVM, criteriaState.criteriaEntityList, isEditable])
    
    const handleEdit = useCallback(() => {
        criteriaVM.onEdit(currentEntity);
    },[criteriaVM, currentEntity])

    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
          <NbisCommonField
              errorMessages={allFormState}
              isReadOnly={isRead}
              isShowOptional={false}
              readOnlyValue={currentEntity?.chargeOnCompany || ''}
              fieldValue={currentEntity?.chargeOnCompany?.toString()}
              fieldLabel={CRITERIA_CONSTANT.CHARGE_ON_COMPANY}
              isSaveClicked={isSaveClicked}
              fieldType={FieldType.DROPDOWN}
              fieldKey={'chargeOnCompany'}
              isShowMissingError={true}
              maxLength={60}
              requiredFieldList={[]}
              options={criteriaState.dynamicOptions.chargeOnCompanyDropdownOptions}
              onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                  criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [currentEntity?.chargeOnCompany, isRead, criteriaVM, criteriaState.dynamicOptions.chargeOnCompanyDropdownOptions, isSaveClicked, CRITERIA_CONSTANT.CHARGE_ON_COMPANY, allFormState])
    
    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
          <NbisCommonField
              errorMessages={allFormState}
              isReadOnly={isRead}
              isShowOptional={false}
              readOnlyValue={currentEntity?.billToCompany || ''}
              fieldValue={currentEntity?.billToCompany?.toString()}
              fieldLabel={CRITERIA_CONSTANT.BILL_TO_COMPANY}
              isSaveClicked={isSaveClicked}
              fieldType={FieldType.DROPDOWN}
              fieldKey={'billToCompany'}
              isShowMissingError={true}
              maxLength={60}
              requiredFieldList={[]}
              options={criteriaState.dynamicOptions.chargeOnCompanyDropdownOptions}
              onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                  criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [currentEntity?.billToCompany, isRead, criteriaVM, criteriaState.dynamicOptions.chargeOnCompanyDropdownOptions, isSaveClicked, CRITERIA_CONSTANT.BILL_TO_COMPANY, allFormState])
    
    const memoMarshallingType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
          <NbisCommonField
              errorMessages={allFormState}
              isReadOnly={isRead}
              isShowOptional={false}
              readOnlyValue={currentEntity?.marshallingType || ''}
              fieldValue={currentEntity?.marshallingType?.toString()}
              fieldLabel={CRITERIA_CONSTANT.MARSHALLING_TYPE}
              isSaveClicked={isSaveClicked}
              fieldType={FieldType.DROPDOWN}
              fieldKey={'marshallingType'}
              isShowMissingError={true}
              maxLength={60}
              requiredFieldList={[]}
              options={criteriaState.dynamicOptions.marshallingTypeDropdownOptions}
              onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                  criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [currentEntity?.marshallingType, isRead, criteriaVM, criteriaState.dynamicOptions.marshallingTypeDropdownOptions, isSaveClicked, CRITERIA_CONSTANT.MARSHALLING_TYPE, allFormState])

    const memoActiveInd = useMemo(() =>
        <>
          <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={CRITERIA_CONSTANT.ACTIVE_IND} 
                fieldType={FieldType.TEXT} 
                isReadOnly={true}
                onFieldChange={()=>{}}/>
            <HPHCheckbox
                label={""}
                checked={currentEntity?.activeInd==='Y'}
                disabled={isRead}
                onChange={(e) => criteriaVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        </>
    , [currentEntity?.activeInd, isRead, criteriaVM, CRITERIA_CONSTANT.ACTIVE_IND])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return (
        <div className='side-form-content-wrapper' style={{ width:'27vw', height:"99vh"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                            <SidebarTitle>
                                <SidebarTitle>{isAdd?'NEW GROUP': currentEntity.marshallingType}</SidebarTitle>
                                {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </SidebarTitle>
                            <SidebarActionCross>
                                {(!isAdd && (isEditable && isRead)) &&
                                    <>
                                        {
                                            (
                                                (isEditable )
                                            )
                                            && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                                        }
                                    </>}
                            </SidebarActionCross>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
           
            <div className={'add-edit-form'} style={{ height:'87vh', maxHeight:'87vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'} style={{ height:'79vh', maxHeight:'79vh' , overflow: 'auto'}}>
                        <CriteriaItemContainer>
                            {memoChargeOnCompany}
                            {memoBillToCompany}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoMarshallingType}
                            {memoActiveInd}
                        </CriteriaItemContainer>
                    </div>
                </div>
                <div className='im-charge-data-search-criteria-buttons' >
                    {
                        <>
                            <HPHButton label={isRead?'Close':'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                            {!isRead&&<HPHButton label={isAdd?'Add':"Update"} size={'Small'} theme={'Primary'} onClick={()=>handleSaveClick()} />}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

