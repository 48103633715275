import { EMPTY_MARL_TYPE_EXCL_ENTITY, MarshallingTypeExclusionEntity } from "domain/entity/MarshallingTypeExclusion/MarshallingTypeExclusionEntity";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { MarshallingTypeExclusionRepository } from "domain/repository/MarshallingTypeExclusion/MarshallingTypeExclusionRepo";
import { OpsLineRepository } from "domain/repository/OpsLine/OpsLineRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { TerminalRepository } from "domain/repository/Terminal/TerminalRepo";
import _ from "lodash";
import { CntrStatusDroOpts } from "presentation/constant/DropDownOptions/Company/CntrStatusDroOpts";
import { MarshallingTypeExclusionModel } from "presentation/model/MarshallingTypeExclusion/MarshallingTypeExclusionModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";
import { marshallingTypeExclusionValidationSchema } from "presentation/constant/MarshallingTypeExclusion/MarshallingTypeExclusionValidationSchema";
import { Validation } from "presentation/constant/Validation";

interface CriteriaVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<MarshallingTypeExclusionModel>> | ((value: SetStateAction<MarshallingTypeExclusionModel>) => void),
    ],
    marshallingTypeExclRepo: MarshallingTypeExclusionRepository,
    opsLineRepo: OpsLineRepository,
    companyRepo: CompanyRepository,
    parameterDetailRepo: ParameterDetailRepository,
    terminalRepo: TerminalRepository,
}

export const MarshallingTypeExclusionVM = ({dispatch, marshallingTypeExclRepo, opsLineRepo, companyRepo, parameterDetailRepo, terminalRepo}:CriteriaVMProps) => {
    const [criteriaDispatch] = dispatch;

    const onRowDoubleClick = (entity: MarshallingTypeExclusionEntity) => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowDetail: true,
                currentSelectedRow: entity,  
                masterState:{
                    ...prevState.masterState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSaveClicked: false,
                    isSliderOpen: true,
                    allFormState: {}
                },
            }
        })
    }
    
    const loadDropdownOption = async () => {
       
        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                criteriaDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeOnCompanyDropdownOptions: [
                            ...companyCodeDropdownOptions],
                    }
                }))
            }
        )
        
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("MARSHALLING_TYPE").then(
            marshallingTypeList => {
                const marshallingTypeDropdownOption = marshallingTypeList?.map((marshallingType) => ({
                    dropdownLabel: marshallingType.parameterDtlCode,
                    tagLabel: marshallingType.parameterDtlCode,
                    value: marshallingType.parameterDtlCode,
                })) ?? []

                criteriaDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        marshallingTypeDropdownOptions: marshallingTypeDropdownOption
                    }
                }))
            }
        )
        
        criteriaDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                cntrStatusDropdownOptions: CntrStatusDroOpts().getDroOptsModel()
            }
        }))
    }

    const searchEmptyPoolExclusion = async() => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                isBackFromDetail:false,
                selectedRows: [],
                criteriaEntityList: [],
                currentSelectedRow: {...EMPTY_MARL_TYPE_EXCL_ENTITY},
            }
        })
        await marshallingTypeExclRepo.getMarshallingTypeExclusion().then((data) => {
            data = data.filter(data => data.exclusionType === 'MarshallingTypeExclusionEntity')
            criteriaDispatch(prevState => {
                return {
                    ...prevState,
                    criteriaEntityList: data,
                    selectedRows: [],
                }
            })
        }).catch((error) => {
            return [];
        })

    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        criteriaDispatch(prevState => {

            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingCriteriaEntity: {
                        ...prevState.masterState.editingCriteriaEntity,
                        [fieldKey]: val,
                    },
                    allFormState:{
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }

    const onSave =  async(currentCriteriaEty: MarshallingTypeExclusionEntity, isAdd: boolean) => {
        
        const valHdrResult = await Validation(marshallingTypeExclusionValidationSchema).ValidateFormOnly(currentCriteriaEty);
        
        if (valHdrResult ) {
            let validatedTariffResult: {[x: string]: string} = {};  
            if (valHdrResult) {  
                validatedTariffResult = { ...validatedTariffResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };  
            }  

            criteriaDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedTariffResult
                        },

                    }                        
                }
            });
            return validatedTariffResult;
        } else {

            let res = null;
            if(isAdd){
                res = await marshallingTypeExclRepo.createNewCriteria(currentCriteriaEty);
            }else{
                res = await marshallingTypeExclRepo.updateCriteria(currentCriteriaEty);
            }
            
            if(res.success){    
                onCloseClick();
            }else{
                return res.data;
            }
        }
    }

    const onCloseClick = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackFromDetail:true,
                selectedRows:[],
                currentSelectedRow: {...EMPTY_MARL_TYPE_EXCL_ENTITY},
                masterState:{
                    ...prevState.masterState,
                    isAdd:false,
                    isEditable:false,
                    isRead: true,
                    isTabularDataActive: true,
                    isSliderOpen: false,
                    editingCriteriaEntity: EMPTY_MARL_TYPE_EXCL_ENTITY,
                },
            }
        });
    }

    const onSaveClicked =() => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    
    const onEdit = (currentTarCodeEty: MarshallingTypeExclusionEntity)=>{
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isRead: false,
                    isEditable: true, 
                    editingCriteriaEntity:{
                        ...currentTarCodeEty,
                    }
                },
            }
        })
    }
    
    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        criteriaDispatch(prevState => ({
            ...prevState,
            masterState:{
                ...prevState.masterState,
                editingCriteriaEntity : {
                    ...prevState.masterState.editingCriteriaEntity,
                    [fieldName]: checked?"Y":"N",
                }
            }
        }))
    }

    const onCancel = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    currentSelectItem: EMPTY_MARL_TYPE_EXCL_ENTITY,
                    isSliderOpen: false,
                    allFormState:{}
                }
            }
        })
    }

    const onComponentSaveClicked =() => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const updateSelectedComponentRows = async (rows: any[]) => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: rows,
            }
        })
    }

    const onAdd = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,  
                    editingCriteriaEntity: EMPTY_MARL_TYPE_EXCL_ENTITY,   
                    isAdd:true,
                    isSliderOpen: true,
                    isEditable:false,
                    isRead: false,
                    isSaveClicked: false,
                }
            }
        })
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any, objName?: string) => {
        let val: any = fieldValue;
        if(objName && objName === 'date_time'){
          val = new Date(val).toISOString();
        }
        if (_.isArray(val)) {
          val = _.uniq(val?.map((item: any) => item.value || item.key));
        }     
  
        
        criteriaDispatch(prevState => {
            return {
                ...prevState, 
                masterState:{
                    ...prevState.masterState,
                    editingCriteriaEntity: {
                        ...prevState.masterState.editingCriteriaEntity,
                        [fieldKey]: val,
                    },
                    allFormState:{
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }          
            }
        });
    }

    return {
        onHeaderFieldChange: onHeaderFieldChange,
        loadDropdownOption: loadDropdownOption,
        onRowDoubleClick: onRowDoubleClick,
        searchEmptyPoolExclusion: searchEmptyPoolExclusion,
        onSave: onSave,
        onCloseClick: onCloseClick,
        onSaveClicked: onSaveClicked,
        onEdit: onEdit,
        onCheckboxChange: onCheckboxChange,
        onCancel: onCancel,
        onAdd: onAdd,
        onComponentSaveClicked: onComponentSaveClicked,
        onFieldChange: onFieldChange,
        updateSelectedComponentRows: updateSelectedComponentRows,
    }
}