import { MarshallingTypeExclusionConstant } from "presentation/constant/MarshallingTypeExclusion/MarshallingTypeExclusionConstant";
import { useMarshallingTypeExclusionVM } from "presentation/hook/MarshallingTypeExclusion/useMarshallingTypeExclusionVM";
import { useMarshallingTypeExclusionTracked } from "presentation/store/MarshallingTypeExclusion/MarshallingTypeExclusionProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import { MarshallingTypeExclusionPanel } from "./Form/MarshallingTypeExclusionPanel";
import MarshallingTypeExclusionTablePanel from "./Table/MarshallingTypeExclusionTablePanel";
export const MarshallingTypeExclusionMaintenance: React.FC = () => {
    const [criteriaState] = useMarshallingTypeExclusionTracked();
    const { isSliderOpen } = criteriaState.masterState;

    const [isLoading, setIsLoading] = useState(false);
    const criteriaVM = useMarshallingTypeExclusionVM();

    useEffect(() => {
        const initialScreen = async () => {
            setIsLoading(true);
            try {
                const results = await Promise.allSettled([
                    criteriaVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(async () => {
            criteriaVM.searchEmptyPoolExclusion().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }).catch(error => {
            setIsLoading(false)
        });

    }, [criteriaVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <div className={`main-comp-wrapper${isSliderOpen ? '' : ' im-hide-side-form-draggable'}`}>
        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderTitle>
                {MarshallingTypeExclusionConstant.Title.MARL_TYPE_EXCL_MAIN}
            </HeaderTitle>
        </Sidebarheader>
        <SliderPanel
            isOpen={isSliderOpen}
            leftSectionWidth={isSliderOpen ? '73%' : '100%'}
            rightSectionWidth={isSliderOpen ? '28%' : '0%'}
            draggable={true}
            leftChildren={<MarshallingTypeExclusionTablePanel />}
            rightChildren={<MarshallingTypeExclusionPanel />} />

    </div>
}