export interface MarshallingTypeExclusionEntity {
    id: number | null,
    chargeOnCompany: string | null,  
    billToCompany: string | null,
    marshallingType: string | null,
    exclusionType: string | null,
    activeInd: "Y" | "N",
    effectiveDate: Date | null,
    expiryDate: Date | null,

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_MARL_TYPE_EXCL_ENTITY : MarshallingTypeExclusionEntity = {
    id: null,
    opsLine: null,
    chargeOnCompany: null,  
    billToCompany: null,
    marshallingType: null,
    exclusionType: 'MarshallingTypeExclusionEntity',
    activeInd: "Y",
    effectiveDate: null,
    expiryDate: null,
}